@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./fonts.css";
@import "./wysiwyg.css";

html {
  @apply text-base font-light;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

body {
  @apply relative;
}

em>strong,
em>b {
  @apply font-bold;
}

html.no-scroll-mobile {
  @apply overflow-y-hidden md:overflow-y-auto;
}

.text-xl:not([class*="font-mencken"]):not([class*="font-menckenHead"]):not([class*="font-menckenNarrow"]):not([class*="font-tusker"]):not([class*="font-rama"]):not([class*="font-instrument"]):not([class*="font-neue"]),
.text-2xl:not([class*="font-mencken"]):not([class*="font-menckenHead"]):not([class*="font-menckenNarrow"]):not([class*="font-tusker"]):not([class*="font-rama"]):not([class*="font-instrument"]):not([class*="font-neue"]),
.text-3xl:not([class*="font-mencken"]):not([class*="font-menckenHead"]):not([class*="font-menckenNarrow"]):not([class*="font-tusker"]):not([class*="font-rama"]):not([class*="font-instrument"]):not([class*="font-neue"]),
.text-4xl:not([class*="font-mencken"]):not([class*="font-menckenHead"]):not([class*="font-menckenNarrow"]):not([class*="font-tusker"]):not([class*="font-rama"]):not([class*="font-instrument"]):not([class*="font-neue"]),
.text-5xl:not([class*="font-mencken"]):not([class*="font-menckenHead"]):not([class*="font-menckenNarrow"]):not([class*="font-tusker"]):not([class*="font-rama"]):not([class*="font-instrument"]):not([class*="font-neue"]),
.text-6xl:not([class*="font-mencken"]):not([class*="font-menckenHead"]):not([class*="font-menckenNarrow"]):not([class*="font-tusker"]):not([class*="font-rama"]):not([class*="font-instrument"]):not([class*="font-neue"]) {
  @apply font-display;
}

/* Input Styles */
input[type="search" i]::-webkit-search-cancel-button {
  appearance: none;
}

/* Link Styles */
a:not([class]) {
  @apply text-link transition-colors ease-out relative underline;
}

/* TODO adjust link styles after Dustin reviews */
.inline-link {
  @apply hover:underline;
}

.jump-link--plain,
.jump-link {
  @apply relative inline-block font-semibold;
}

.jump-link--plain:hover:before,
.jump-link:before {
  @apply absolute bottom-0 w-full;
  content: "";
  height: 1px;
  background-color: currentColor;
}

/* table of contents scroll offset */
main>section[id]:before {
  --section-offset: 48;
  content: "";
  display: block;
  height: calc(var(--section-offset) * 1px);
  margin: calc(var(--section-offset) * -1px) 0 0;
}

@screen md {
  main>section[id]:before {
    --section-offset: 80;
  }
}

/* End Link Styles */

.btn:not(.btn-flat) {
  @apply rounded-pill;
  @apply border border-black-200;
  @apply py-2 px-5;
  @apply text-sm font-semibold;
  @apply transition-all hover:bg-blue focus:bg-blue hover:text-white focus:text-white hover:border-blue focus:border-blue hover:shadow;
  @apply disabled:opacity-50 disabled:bg-transparent disabled:text-current disabled:border-black-200;
  @apply focus:outline-none;
}

.btn.btn-md {
  @apply px-4 py-3;
}

.btn.btn-solid {
  @apply bg-white disabled:bg-black-300 text-black disabled:text-white disabled:opacity-100 disabled:border-black-300;
}

.btn.btn-flat {
  @apply text-sm font-semibold;
  @apply text-blue hover:text-black focus:text-black disabled:text-blue disabled:opacity-50 underline transition-all;
  @apply outline-none focus:outline-none;
}

.btn.btn-flat.btn-inverted {
  @apply text-white hover:text-blue-300 focus:text-blue-300 hover:bg-transparent focus:bg-transparent;
  @apply disabled:text-white disabled:bg-transparent;
  @apply outline-none focus:outline-none;
}

.btn.btn-flat.active {
  @apply text-black;
}

.btn.btn-dark {
  @apply bg-black text-white border-black;
  @apply disabled:bg-transparent disabled:border-black-400 disabled:text-black;
}

.btn.btn-primary {
  @apply text-white bg-blue border-blue;
  @apply hover:bg-[#267DFF] hover:border-[#267DFF] focus:bg-[#267DFF] focus:border-[#267DFF];
  /** one off color token (15% increase of primary)*/
  @apply disabled:border-blue-300 disabled:bg-blue-300 disabled:text-white disabled:opacity-100;
}

.btn.btn-inverted {
  @apply border-black-300 text-white;
  @apply hover:bg-white focus:bg-white hover:text-black focus:text-black hover:border-white focus:border-white;
  @apply disabled:bg-black-300 disabled:border-black-300 disabled:text-white disabled:opacity-100;
}

.btn.btn-inverted.btn-border-white {
  @apply border-white;
}

.btn.hover-white {
  @apply hover:bg-white focus:bg-white hover:text-blue focus:text-blue hover:border-white focus:border-white;
}

.divider {
  @apply mt-4 text-black-100;
}

/**
 * Additional utility groups
 */
@layer utilities {
  .card-link:after {
    /* requires position relative on the
    parent card */
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .link-underline {
    @apply relative;
  }

  .link-underline:after {
    content: "";
    @apply absolute bottom-0 left-0;
    @apply block w-full border-t border-blue mt-2;
  }

  .scroll-snap-none {
    scroll-snap-type: none;
  }

  .scroll-snap-x {
    scroll-snap-type: x;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .scroll-snap-x-mandatory {
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .scroll-snap-y {
    scroll-snap-type: y;
  }

  .snap-align-center {
    scroll-snap-align: center;
  }

  .snap-align-start {
    scroll-snap-align: start;
  }

  .element-count:before {
    content: counter(list-item) ". ";
  }

  .no-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
}

@layer components {

  /* Typography Helpers*/
  .heading-01-bold {
    @apply text-3xl lg:text-6xl font-bold font-display;
  }

  .heading-01 {
    @apply text-3xl lg:text-6xl font-display;
  }

  .heading-02 {
    @apply text-2xl lg:text-4xl font-bold font-display;
  }

  .heading-03 {
    @apply text-xl lg:text-3xl font-bold font-display;
  }

  .heading-04 {
    @apply text-lg lg:text-2xl font-bold font-display;
  }

  .heading-05-bold {
    @apply text-lg lg:text-xl font-bold font-display;
  }

  .heading-05 {
    @apply text-lg lg:text-xl font-display;
  }

  .body-base {
    @apply text-base lg:text-xl;
  }

  .body-lg {
    @apply text-md lg:text-lg;
  }

  .body-sm {
    @apply text-lg lg:text-xl;
  }

  .label-base {
    @apply text-base font-bold;
  }

  .label-sm {
    @apply text-sm font-bold tracking-wider;
  }

  .article-h1 {
    @apply text-3xl lg:text-5xl;
  }

  .article-h2 {
    @apply text-2xl lg:text-3xl;
  }

  .article-h3 {
    @apply text-xl lg:text-2xl;
  }

  .bg-topography {
    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(https://lonelyplanetstatic.imgix.net/marketing/2023/ebookLibrary/topo.png?auto=format&w=1920&h=1080&fit=crop&q=40),
      linear-gradient(to right,
        rgba(172, 201, 244, 1),
        rgba(240, 246, 255, 1),
        rgba(172, 201, 244, 1));
  }

  .bg-topogradient {
    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(https://lonelyplanetstatic.imgix.net/marketing/2023/ebookLibrary/topo.png?auto=format&w=1920&h=1080&fit=crop&q=40),
      linear-gradient(to bottom, rgba(55, 69, 89, 1), rgba(86, 55, 83, 1));
  }

  .bg-gradient-green {
    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(https://lonelyplanetstatic.imgix.net/marketing/2023/ebookLibrary/topo.png?auto=format&w=1920&h=1080&fit=crop&q=40),
      linear-gradient(to bottom, #607d6d, #374559);
  }

  .bg-gradient-blue {
    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(https://lonelyplanetstatic.imgix.net/marketing/2023/ebookLibrary/topo.png?auto=format&w=1920&h=1080&fit=crop&q=40);
    background-color: #374559;
  }

  .bg-pastels {
    background: radial-gradient(ellipse farthest-corner at 66% 30%,
        #64a2dc,
        transparent 30%),
      radial-gradient(ellipse at 100% 0%, #80b7eb, transparent 43%),
      linear-gradient(145deg, #e9f4f1 10%, #fdf7eb 75%, #e9f4f1 95%);
    background-blend-mode: hue;
  }
}
