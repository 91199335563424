/**
 * Pangea Display
 */
@font-face {
  font-family: "Pangea Display";
  src:
    url("https://assets.staticlp.com/fonts/pangea/Pangea-Regular.woff2")
      format("woff2"),
    url("https://assets.staticlp.com/fonts/pangea/Pangea-Regular.woff")
      format("woff");
  font-style: normal;
  font-stretch: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Pangea Display";
  src:
    url("https://assets.staticlp.com/fonts/pangea/Pangea-Italic.woff2")
      format("woff2"),
    url("https://assets.staticlp.com/fonts/pangea/Pangea-Italic.woff")
      format("woff");
  font-style: italic;
  font-stretch: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Pangea Display";
  src:
    url("https://assets.staticlp.com/fonts/pangea/Pangea-SemiBold.woff2")
      format("woff2"),
    url("https://assets.staticlp.com/fonts/pangea/Pangea-SemiBold.woff")
      format("woff");
  font-style: normal;
  font-stretch: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Pangea Display";
  src:
    url("https://assets.staticlp.com/fonts/pangea/Pangea-Bold.woff2")
      format("woff2"),
    url("https://assets.staticlp.com/fonts/pangea/Pangea-Bold.woff")
      format("woff");
  font-style: normal;
  font-stretch: normal;
  font-weight: 600;
  font-display: swap;
}

/**
 * Pangea Text
 */
@font-face {
  font-family: "Pangea Text";
  src:
    url("https://assets.staticlp.com/fonts/pangea-text/PangeaText-Regular.woff2")
      format("woff2"),
    url("https://assets.staticlp.com/fonts/pangea-text/PangeaText-Regular.woff")
      format("woff");
  font-style: normal;
  font-stretch: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Pangea Text";
  src:
    url("https://assets.staticlp.com/fonts/pangea-text/PangeaText-Italic.woff2")
      format("woff2"),
    url("https://assets.staticlp.com/fonts/pangea-text/PangeaText-Italic.woff")
      format("woff");
  font-style: italic;
  font-stretch: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Pangea Text";
  src:
    url("https://assets.staticlp.com/fonts/pangea-text/PangeaText-SemiBold.woff2")
      format("woff2"),
    url("https://assets.staticlp.com/fonts/pangea-text/PangeaText-SemiBold.woff")
      format("woff");
  font-style: normal;
  font-stretch: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Pangea Text";
  src:
    url("https://assets.staticlp.com/fonts/pangea-text/PangeaText-Bold.woff2")
      format("woff2"),
    url("https://assets.staticlp.com/fonts/pangea-text/PangeaText-Bold.woff")
      format("woff");
  font-style: normal;
  font-stretch: normal;
  font-weight: 600;
  font-display: swap;
}

/**
 * Apercu (Monospace)
 */
@font-face {
  font-family: "Apercu Mono";
  src:
    url("https://assets.staticlp.com/fonts/apercu/ApercuMono-Regular-Pro.woff2")
      format("woff2"),
    url("https://assets.staticlp.com/fonts/apercu/ApercuMono-Regular-Pro.woff")
      format("woff"),
    url("https://assets.staticlp.com/fonts/apercu/ApercuMono-Regular-Pro.ttf")
      format("truetype");
  font-style: normal;
  font-stretch: normal;
  font-weight: 300;
  font-display: swap;
}

/**
 * Tusker
 */
@font-face {
  font-family: "Tusker Grotesk";
  src:
    url("https://assets.staticlp.com/fonts/Tusker/TuskerGrotesk-3500Medium.woff2")
      format("woff2"),
    url("https://assets.staticlp.com/fonts/Tusker/TuskerGrotesk-3500Medium.woff")
      format("woff"),
    url("https://assets.staticlp.com/fonts/Tusker/TuskerGrotesk-3500Medium.ttf")
      format("truetype"),
    url("https://assets.staticlp.com/fonts/Tusker/TuskerGrotesk-3500Medium.otf")
      format("opentype");
  font-display: block;
}

@font-face {
  font-family: "Tusker Grotesk";
  src:
    url("https://assets.staticlp.com/fonts/Tusker/TuskerGrotesk-7700Bold.woff2")
      format("woff2"),
    url("https://assets.staticlp.com/fonts/Tusker/TuskerGrotesk-7700Bold.woff")
      format("woff"),
    url("https://assets.staticlp.com/fonts/Tusker/TuskerGrotesk-7700Bold.ttf")
      format("truetype"),
    url("https://assets.staticlp.com/fonts/Tusker/TuskerGrotesk-7700Bold.otf")
      format("opentype");
  font-display: block;
  font-weight: 700;
}

/**
 * Mencken Standard (Narrow)
 */
@font-face {
  font-family: "Mencken Std Narrow";
  src:
    url("https://use.typekit.net/af/f83182/00000000000000007735b804/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/f83182/00000000000000007735b804/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/f83182/00000000000000007735b804/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

/**
 * Rama
 */
/* Rama Gothic E - Heavy */
@font-face {
  font-family: "Rama Gothic";
  src:
    url("https://use.typekit.net/af/3e46fd/00000000000000007735c17e/30/l?subset_id=2&fvd=n9&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/3e46fd/00000000000000007735c17e/30/d?subset_id=2&fvd=n9&v=3")
      format("woff"),
    url("https://use.typekit.net/af/3e46fd/00000000000000007735c17e/30/a?subset_id=2&fvd=n9&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
}
/* Rama Gothic E - SemiBold */
@font-face {
  font-family: "Rama Gothic";
  src:
    url("https://use.typekit.net/af/b91566/00000000000000007735c17a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") 
      format("woff2"),
    url("https://use.typekit.net/af/b91566/00000000000000007735c17a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") 
      format("woff"),
    url("https://use.typekit.net/af/b91566/00000000000000007735c17a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") 
      format("opentype");
  font-display:auto;
  font-style:normal;
  font-weight:600;
  font-stretch:normal;
  }
/* Rama Gothic E - Bold */
@font-face {
  font-family: "Rama Gothic";
  src:
    url("https://use.typekit.net/af/ac16d9/00000000000000007735c17c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") 
      format("woff2"),
    url("https://use.typekit.net/af/ac16d9/00000000000000007735c17c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") 
      format("woff"),
    url("https://use.typekit.net/af/ac16d9/00000000000000007735c17c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") 
      format("opentype");
  font-display:auto;
  font-style:normal;
  font-weight:700;
  font-stretch:normal;
  }

/**
 * Neue Hass
 */
/* Neue Haas Grotesk Text- 55 Roman */
@font-face {
  font-family:"Neue Haas Grotesk Text";
  src:
    url("https://use.typekit.net/af/0230dd/00000000000000007735bb33/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") 
      format("woff2"),
    url("https://use.typekit.net/af/0230dd/00000000000000007735bb33/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") 
      format("woff"),
    url("https://use.typekit.net/af/0230dd/00000000000000007735bb33/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") 
      format("opentype");
  font-display:auto;
  font-style:normal;
  font-weight:400;
  font-stretch:normal;
  }  
/* Neue Haas Grotesk Text - 65 Medium */
@font-face {
  font-family:"Neue Haas Grotesk Text";
  src:
  url("https://use.typekit.net/af/160664/00000000000000007735bb32/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") 
    format("woff2"),
  url("https://use.typekit.net/af/160664/00000000000000007735bb32/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") 
    format("woff"),
  url("https://use.typekit.net/af/160664/00000000000000007735bb32/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") 
    format("opentype");
  font-display:auto;
  font-style:normal;
  font-weight:500;
  font-stretch:normal;
  }    
/* Neue Haas Grotesk Text - 75 Bold */
@font-face {
  font-family:"Neue Haas Grotesk Text";
  src:
    url("https://use.typekit.net/af/305037/00000000000000007735bb39/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") 
      format("woff2"),
    url("https://use.typekit.net/af/305037/00000000000000007735bb39/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") 
      format("woff"),
    url("https://use.typekit.net/af/305037/00000000000000007735bb39/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") 
      format("opentype");
  font-display:auto;
  font-style:normal;
  font-weight:700;
  font-stretch:normal;
  }

/**
 * Instrument
 */
/* Instrument Serif - Italic */
@font-face {
  font-family:"Instrument Serif";
  src:
    url("https://use.typekit.net/af/413fc5/0000000000000000774dc261/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") 
      format("woff2"),
    url("https://use.typekit.net/af/413fc5/0000000000000000774dc261/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") 
      format("woff"),
    url("https://use.typekit.net/af/413fc5/0000000000000000774dc261/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") 
      format("opentype");
  font-display:auto;
  font-style:italic;
  font-weight:400;
  font-stretch:normal;
  } 
/* Instrument Serif - Regular */
@font-face {
  font-family:"Instrument Serif";
  src:
    url("https://use.typekit.net/af/da8d18/0000000000000000774dc260/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") 
      format("woff2"),
    url("https://use.typekit.net/af/da8d18/0000000000000000774dc260/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") 
      format("woff"),
    url("https://use.typekit.net/af/da8d18/0000000000000000774dc260/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") 
      format("opentype");
  font-display:auto;
  font-style:normal;
  font-weight:400;
  font-stretch:normal;
  }