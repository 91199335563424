/* stylelint-disable no-descending-specificity */

.dangerHTML p {
  @apply mb-6;
  @apply text-sm;
  @apply md:text-base;
}

.dangerHTML h1,
.dangerHTML h2,
.dangerHTML h3,
.dangerHTML h4,
.dangerHTML h5,
.dangerHTML h6 {
  @apply font-display;
  @apply font-bold;
  @apply mb-2.5;
}
.dangerHTML h1 {
  @apply text-2xl;
  @apply md:text-3xl;
  @apply xl:text-4xl;
}

.dangerHTML h2 {
  @apply text-2xl;
  @apply md:text-4xl;
  @apply xl:text-3xl;
}

.dangerHTML h3 {
  @apply text-lg;
  @apply md:text-xl;
  @apply xl:text-2xl;
}

.dangerHTML h4 {
  @apply text-base;
  @apply md:text-lg;
  @apply xl:text-xl;
}

.dangerHTML h5 {
  @apply text-sm;
  @apply md:text-lg;
}

.dangerHTML h6 {
  @apply text-sm;
  @apply md:text-base;
}

.dangerHTML ul,
.dangerHTML ol {
  @apply text-left;
  @apply ml-10;
}

.dangerHTML ul li,
.dangerHTML ol li {
  @apply mb-6;
}

.dangerHTML ul {
  @apply list-disc;
}

.dangerHTML ol {
  @apply list-decimal;
}
