.wysiwyg .featured {
  font-size: 2.25rem;
}

.wysiwyg .subsection {
  margin-top: 3rem;
}

.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4 {
  @apply font-semibold;
  @apply text-lg;
  @apply mt-6 lg:mt-8;
}
.wysiwyg p {
  @apply mt-6 lg:mt-8;
}

@screen sm {
  .wysiwyg .featured {
    @apply text-lg;
  }
}

@media (min-width: 600px) {
  .wysiwyg .featured {
    margin-bottom: 7rem;
  }
}

@media (min-width: 720px) {
  .wysiwyg .featured {
    font-size: 3.5rem;
  }

  .wysiwyg {
    @apply text-lg;

    line-height: 1.8;
  }
}
